<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {CatalogMenuData} from "~vue/catalog-menu/types";
import {useTabCategories} from "~vue/catalog-menu/lib";
import CatalogMenuTabs from "~vue/catalog-menu/ui/CatalogMenuTabs.vue";
import CatalogMenuCategory from "~vue/catalog-menu/ui/CatalogMenuCategory.vue";
import CatalogMobileHomeMenu from "~vue/catalog-menu/ui/home24-mobile-menu/CatalogMobileHomeMenu.vue";
import isHome24 from "~/helpers/is-home24";

const props = defineProps<{
    groupId: string
}>()

const menu = ref<CatalogMenuData | null>(null);

const {
	activeTab,
	activeCategory,
	visibleCategory,
	showMenuGroups,
	groupsIsVisible,
} = useTabCategories(menu)

const groupsClassName = computed(() => ({
	wrapper__groups: true,
	'wrapper__groups--show': groupsIsVisible.value,
}))

const isShow = ref<boolean>(false);

fetch(`/main-menu/full/${props.groupId}/`)
    .then((r) => r.json())
    .then((result) => {
        if (result.success) {
            menu.value = result.data
        }
    })

onMounted(() => {
    let timerID: ReturnType<typeof setTimeout> | undefined;

    // этот обработчик нужен для решения бага, когда мы переходим в какую то категорию, а потом жмем кнопку назад и получаем пустое меню
    window.addEventListener('pageshow', () => {
        const isMenuOpened = document.querySelector('.main-menu > .dropdown-screen--opened')
        
        if (isMenuOpened) {
            isShow.value = true
        }
    })

    document.addEventListener('dropdownOpen', (e: Event) => {
        const target = e.target as HTMLElement;
        const isMenuDropdown = target.parentElement?.classList.contains('main-menu');

        if (isMenuDropdown) {
            clearTimeout(timerID);
        }

        isShow.value = Boolean(isMenuDropdown);
    })

    document.addEventListener('dropdownClose', (e) => {
        const target = e.target as HTMLElement;
        const isMenuDropdown = target.parentElement?.classList.contains('main-menu');

        if (isMenuDropdown) {
            clearTimeout(timerID);
            timerID = setTimeout(() => isShow.value = false, 500);
        }
    })

    document.addEventListener('showMenuGroups', () => {
        showMenuGroups.value = true
    })
})

const isMobileWindow = computed(() => {
    return document.documentElement.clientWidth <= 560 && isHome24()
})
</script>

<template>
    <CatalogMobileHomeMenu v-if="isMobileWindow && isShow"/>
    <template v-if="isShow && !isMobileWindow">
        <nav
            v-if="menu && activeTab && activeCategory"
            class="wrapper col-span-12"
        >
            <button
                type="button"
                aria-hidden="true"
                :class="{
				wrapper__toggle: true,
				'wrapper__toggle--show': showMenuGroups,
			}"
                @click="showMenuGroups = false"
            >
                <span aria-hidden="true" class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14" width="8" height="14" fill="none">
                        <path stroke="currentColor" stroke-width="2" d="M7 1 1 7l6 6"></path>
                    </svg>
                </span>

                <span>{{ activeTab.title }}</span>
            </button>

            <div
                :class="{
				wrapper__menu: true,
				'wrapper__menu--groups': showMenuGroups,
				'container-no-indents': true,
			}"
            >
                <CatalogMenuTabs
                    v-model="activeTab"
                    v-model:category="activeCategory"
                    :tabs="menu.tabs"
                    :standalone-links="menu.links"
                    :template="isHome24() ? 'tabs' : 'accordion'"
                    class="wrapper__tabs"
                />

                <div class="wrapper__categories-container">
                    <CatalogMenuCategory
                        v-if="visibleCategory"
                        :category="visibleCategory"
                        :class="groupsClassName"
                    />
                </div>
            </div>
        </nav>
    </template>
</template>

<style scoped lang="scss">
.wrapper {
	&__toggle {
		@apply h-10
            flex
            text-pale-sky-70
            text-li
            w-full
            gap-2.5
            items-center
            opacity-0
            transition-opacity
            duration-300
            ease-ease
            mb-6 xsm:mb-10
            sm:hidden;

		&--show {
			@apply opacity-100;
		}

		&:deep(span) {
			@apply xsm:hidden;
		}
	}

	&__menu {
        @apply grid
            grid-cols-6 xsm:grid-cols-12
            gap-x-6 md:gap-x-10
            mx-auto
            items-start
            overflow-initial
            max-w-[120rem];

		&--groups {
			.wrapper__tabs {
				@apply translate-x-[calc(150%)] xsm:translate-x-0;
			}

			.wrapper__categories-container {
				@apply -translate-x-0 xsm:translate-x-0;
			}
		}
	}

	&__tabs {
		@apply bg-white
            z-[4]
            xsm:sticky
            xsm:top-0
            transition-transform xsm:transition-none
            row-start-1
            row-end-2
            col-start-1
            col-end-7
            duration-300
            ease-ease
            xsm:col-span-5 sm:col-span-4 md:col-span-3 xlg:col-span-2;

		&::after {
			@apply
                content-[""]
                md:content-none
                absolute
                h-screen
                bg-white
                block
                z-[3]
                top-0
                w-[calc(100%+1rem)] xsm:w-[calc(100%+1.5rem)] sm:w-[calc(100%+2.5rem)]
                -left-[1rem] xsm:-left-[1.5rem] sm:-left-[2.5rem]
            ;
		}
	}

	&__categories-container {
		@apply grid
			h-max
			row-start-1
			row-end-2
			col-start-1
			col-end-7
			transition-transform xsm:transition-none
			-translate-x-[calc(150%)] xsm:translate-x-0
			duration-300
			ease-ease
			relative
			overflow-y-clip
			xsm:col-span-7 sm:col-span-8 md:col-span-9 xlg:col-span-10
			md:overflow-hidden;
	}

	&__groups {
		transition-property: opacity, transform;

		@apply
            xsm:opacity-0
			bg-white
            xsm:-translate-x-full
            duration-0 xsm:duration-500
			ease-in-out
			row-start-1
			row-end-2
			w-full
			col-start-1
			col-end-2;

		&--show {
			@apply opacity-100
				translate-x-0
				static
				z-[1];
		}
	}
}
</style>
